<!--
 * @Description: 消息
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-19 22:44:14
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/user/message.vue
-->
<template>
  <div class="app-wrapper wrapper-view">
    <div class="wrapper-title">消息</div>
    <div v-loading="isLoading">
      <el-form ref="queryForm" size="small" :inline="true" label-width="68px">
      </el-form>
      <el-table
        :data="tableData"
        stripe
        max-height="666"
        style="width: 100%; margin-top: 21px"
      >
        <el-table-column type="index" label="序号" width="80" align="center" />
        <el-table-column
          prop="created_at"
          label="时间"
          width="240"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="content"
          label="内容"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="state" label="状态" align="center" width="120">
          <template slot-scope="{ row }">
            <div
              v-if="`${row.state}` === '2'"
              :class="['state-btn', 'state-confirm']"
            >
              {{ row.state_text }}
            </div>
            <el-dropdown
              v-if="`${row.state}` === '1'"
              @command="handleCommand($event, row)"
            >
              <div
                :class="['state-btn', 'state-primary']"
                style="cursor: pointer"
              >
                {{ row.state_text }}
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="2">已读</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="page.total > 0"
        :total="page.total"
        :page.sync="page.page"
        :limit.sync="page.size"
        @pagination="getList()"
      />
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../../utils/request";

const URL = {
  list: "api/customer/userMessage/list",
  mark: "api/customer/userMessage/mark",
};
export default {
  data() {
    return {
      tableData: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList(page = this.page.page, size = this.page.size) {
      this.isLoading = true;
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          page,
          limit: size,
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },
    async handleCommand(state, { id }) {
      await HTTP({
        url: URL.mark,
        method: "post",
        data: {
          state,
          id,
        },
      });
      this.getList();
    },
  },
};
</script>
